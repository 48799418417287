
import BHorizontalDivider from 'buetify/lib/components/layout/divider/BHorizontalDivider';
import BMedia from 'buetify/lib/components/layout/media/BMedia';
import BMediaContent from 'buetify/lib/components/layout/media/BMediaContent';
import BMediaLeft from 'buetify/lib/components/layout/media/BMediaLeft';
import BMessage from 'buetify/lib/components/message/BMessage';
import BSubtitle from 'buetify/lib/components/title/BSubtitle';
import BTitle from 'buetify/lib/components/title/BTitle';
import { defineComponent } from 'vue';
import CodeView from '../../../../components/codeView/CodeView.vue';
import GlobalCode from '!!raw-loader!./global.sass';
import MainCode from '!!raw-loader!./main';
import VariablesCode from '!!raw-loader!./variables.sass';
import WebpackConfigCode from '!!raw-loader!./webpack.config';
import VueConfigCode from '!!raw-loader!./vue.config';

export default defineComponent({
	name: 'start-documentation',
	components: {
		BMedia,
		BMediaLeft,
		BMediaContent,
		CodeView,
		BHorizontalDivider,
		BMessage,
		BTitle,
		BSubtitle
	},
	setup() {
		return {
			GlobalCode,
			VariablesCode,
			WebpackConfigCode,
			VueConfigCode,
			MainCode
		};
	}
});
